.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

.abouttext {
  height:20%;
}

.appcontent {
  height:100%;
  /* min-height:1000px; */
  
}

.fcredits {
  font-size:xx-small;
}



.igchanel {
  position:absolute;
  display:block;
  bottom:0px;
}

body html {
  /* background-color: rgba(43, 43, 43, 0); */
  z-index:1rem;
  height: 100%;
  min-height:1000px;
}
.bg-kick {
  background-color:#fbfbfb!important;
}
.Applogo {
  height: 64px;
}

canvas {
  position:absolute;
}

.link {
  text-decoration: none;
}


.homepic {
  text-align:center;
  width:100%;
}


.firstletter {
  font-size:30pt;
  font-weight:bold;
  color:#0071bc;
}

.logoApic {
  width: 400px;
  height:auto;
}

.logoapic {
  width: 100%;
  height:auto;
}

.logo {
  width: 150px;
  height:auto;
}

.fixed {
  position:fixed;
}

.avatar {
  width:50%;
  height:auto;
  border-radius: 100%;
  box-shadow:0px 5px 2px 2px #00000029;
}

.slogan {
  font-size:30pt;
  font-weight:bold;
  color:#ffffff;
}

.sloganbox {
  margin-left:auto;
  margin-right:auto;
}

.logobox {
  margin-left:auto;
  margin-right:auto;
  width:100%;
  max-height:300px;
}

.aboutpic {
  width:100%;
  height:auto;
  padding:.25em;

}


.aboutbox {
  width:90%;
  
}

.aboutcard {
  border:.5px solid #9797973f;
  z-index:0;
  margin:auto;
  box-shadow: 0 1.44rem 1.7rem rgba(0, 0, 0);
}

.octopimpsmall {
  width:100px;
  height:100px;
  float:right;
  right:0;
}

.picrow {
  width:100%;
}

.ztop {
  z-index:0;
}
.round {
  border-radius:10px;
}

.shrink {
  zoom:.6;
}

.footglass {
  background: rgba(255, 255, 255, 0.767);
  backdrop-filter: blur(15px);
  width: 100%;
  height: 105%;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  border: 1px solid #888888a8;
  opacity: 0.3;
}

.footer {
  position:relative;
  bottom: 0;
  left:0;
  right:0;
  width: 100%;
  display: inline-block;
  z-index:100;
  
}

.divider {
  height:20em;
  width:.5em;
  margin-top:-30px;
  margin-left:5px;
}

.divider2 {
  height:10em;
  width:.5em;
  margin-top:-30px;
  margin-left:5px;
}

.phone {
  color:#0071bc;
}

.email {
  color:#af6868;
}

.right {
  right:0;
  float:right;
}

.left {
  left:0;
  float:left;
}


.navbar {
  z-index:100;
  box-shadow: 0 .125rem .25rem rgb(0, 0, 0)!important;
  background-color:white;
  width:100%;
  left:0;
  right:0;
}

.wallpic {
  width:1000px;
  height:auto;
  padding-right:50px;
}

.mr-auto1 {
  margin-left: 60%!important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-head {
  z-index:50;
  /* background-color: #e6e6e6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.App-tail {
  z-index:-1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 700px) {
  body {
    background-position-y: -20%;
  }
  
}

@media screen and (max-width: 992px) {
  .sloganbox {
    margin-left:20%;
  }
  #buybtns {
    display:none;
  }
  .avatar {
    width:75%;
  }
}

@media screen and (max-width: 767px) {
  .divider2 {
    display:none;
  }
  .leftcol {
    padding-left:2em;
  }
}


@media screen and (min-width: 701px) {
  body {
    *background-position-y: 0;
  }
}


.absolute {
  position:absolute;
  
}

.fixed {
  position:fixed;
}

.relative {
  position:relative;
}

.center {
  margin-left:0;
  left:0;
  margin-right:0;
  right:0;
  text-align:center;
}

.under {
  margin-top:65%;
}

/*-----------animations-----------*/

#cover {
  width:75%;
}

.cover1pic {
  width:100%;
  height:auto;
  z-index:5;
  position:absolute;
  left:0;
  right:0;
}

.cover2pic {
  width:100%;
  height:auto;
  z-index:1;
  position:absolute;
  left:0;
  right:0;
}

.cover3pic {
  width:100%;
  height:auto;
  z-index:5;
  position:absolute;
  left:0;
  right:0;
}

.cover4pic {
  width:100%;
  height:auto;
  z-index:5;
  position:absolute;
  left:0;
  right:0;
}

.coverspace {
  padding-bottom:50px;
}

.cover5pic {
  width:100%;
  height:auto;
  z-index:2;
  position:absolute;
  left:0;
  right:0;
}

#cover1key {
  animation-name: cover1keyin;
  animation-duration: 3s;
  animation-delay:1s;
  animation-fill-mode: both;
}

@keyframes cover1keyin {
  0% {   
         
         
         visibility:hidden;     
  }
  100% {
    
       visibility:visible;
  }
}

#cover1pickey {
  animation-name: cover1pickeyin;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode:both;
}

@keyframes cover1pickeyin {
  from { 
         visibility:hidden;  
  }
  to { 
       visibility:visible;  
  }
}

#cover2key {
  animation-name: cover2keyin;
  animation-duration: 3s;
  animation-delay:2s;
  animation-fill-mode: both;
}

@keyframes cover2keyin {
  from { box-shadow: none;
         
         
         visibility:hidden;  
         
  }
  to { 
       
       visibility:visible;  
  }
}

#cover2pickey {
  animation-name: cover2pickeyin;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-fill-mode: both;
}

@keyframes cover2pickeyin {
  from {  box-shadow: none;
         visibility:hidden;  
  }
  to { box-shadow: 0 .175rem .35rem rgba(0, 0, 0);

       visibility:visible;  
  }
}

#cover3key {
  animation-name: cover3keyin;
  animation-duration: 3s;
  animation-delay:3s;
  animation-fill-mode: both;
}

@keyframes cover3keyin {
  from { 
         visibility:hidden;
  }
  to { 
       visibility:visible;

  }
}

#cover3pickey {
  animation-name: cover3pickeyin;
  animation-duration: 3s;
  animation-delay: 3s;
  animation-fill-mode: both;
}

@keyframes cover3pickeyin {
  from { 
         visibility:hidden;  
  }
  to { 
       visibility:visible;  
  }
}

#cover4key {
  animation-name: cover4keyin;
  animation-duration: 3s;
  animation-delay:4s;
  animation-fill-mode: both;
  
}

@keyframes cover4keyin {
  from { 
         visibility:hidden;      
  }
  to { 
         visibility: visible;
  }
}

#cover4pickey {
  animation-name: cover4pickeyin;
  animation-duration: 3s;
  animation-delay: 4s;
  animation-fill-mode: both;
}

@keyframes cover4pickeyin {
  from { 
         visibility:hidden;  
  }
  to { 
       visibility:visible;  
  }
}

#cover5key {
  animation-name: cover5keyin;
  animation-duration: 3s;
  animation-delay: 5s;
  animation-fill-mode: both;
}

@keyframes cover5keyin {
  from {
         
        
         visibility:hidden;  
  }
  to { 
      
       
       visibility:visible;  
  }
}

#cover5pickey {
  animation-name: cover5pickeyin;
  animation-duration: 3s;
  animation-delay: 5s;
  animation-fill-mode: both;
}

@keyframes cover5pickeyin {
  from { 
         visibility:hidden;  
  }
  to { 
       visibility:visible;  
  }
}

/*===========*


#aboutkey {
  animation-name: aboutkeyin;
  animation-duration: 1s;
  z-index:0;
}

@keyframes aboutkeyin {
from { top: -600px;
      z-index: 0;
      box-shadow: 0 .01rem 0.05rem rgba(0, 0, 0, 0.027);
  }
  to { top: 0;
    z-index: 0;
    box-shadow: 0 1.44rem 1.7rem rgba(0, 0, 0);
  }
}

#textkey {
  animation-name: textkeyin;
  animation-duration: .5s;
  animation-delay:1s;
  animation-fill-mode: both;
}

@keyframes textkeyin {
from { opacity: 0%;
  }
  to { opacity: 80%;
  }
}

#logoAkey {
  animation-name: logoAkeyin;
  animation-duration: 2s;
  animation-delay: 1s;
  z-index:0;
}

@keyframes logoAkeyin {
from { opacity: 0%;
  }
  to { opacity: 100%;
  }
}

#quotekey {
  animation-name: quotekeyin;
  animation-duration: .5s;
  animation-delay:3s;
  animation-fill-mode: both;
}

@keyframes quotekeyin {
from { opacity: 0%;
  }
  to { opacity: 80%;
  }
}

/*Stars and bkg*/

.bodybkg {
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position:fixed;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
  z-index:-1;
}



@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:5000px 10000px;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:5000px 10000px;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:5000px 10000px;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position:5000px 10000px;}
}

.stars, .twinkling, .clouds {
position:fixed;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

.stars {
background:rgb(167, 217, 225) repeat top center;
z-index:-4;
}

.sig {
  height:auto;
  width:200px;
}


.clouds{
  background:transparent url(./img/sky/clouds.png) repeat top center;
  z-index:-2;
  zoom:2.5;
-moz-animation:move-clouds-back 400s linear infinite;
-ms-animation:move-clouds-back 400s linear infinite;
-o-animation:move-clouds-back 400s linear infinite;
-webkit-animation:move-clouds-back 400s linear infinite;
animation:move-clouds-back 400s linear infinite;
}